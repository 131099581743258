import React from "react";
import './TreatmentExerciseCard.scss';
import { Text, RatingBar } from "..";
import { useTranslation } from "react-i18next";

export const TreatmentExerciseCard = (props) => {
  const { count, index, difficulty, duration, setSelectedVideo, treatmentLevel, title, thumbnail } = props;
  const { t } = useTranslation();
  const selectVideo = () => {
    setSelectedVideo(index);
  }
  return (
    <div className="TreatmentExerciseCardContainer">
      <Text className="TreatmentExerciseOutsideTitle">{`${t("exercise")} ${count}`}</Text>
      <div className="TreatmentExerciseCardMain">
        <div className="TreatmentExerciseCard">
          <div className="TreatmentExerciseCardHeader">
            <div className="TreatmentExerciseCardInfo">
              <Text className="TreatmentExerciseCardLabel">{title}</Text>
              <div className="TreatmentExerciseCardDifficulty">
                <Text className="TreatmentExerciseCardDifficultyText">{`${t('difficulty')}: `}</Text>
                <RatingBar rating={difficulty} />
              </div>
              <Text className="TreatmentExerciseCardDurationText">{`${t('duration')}: ${duration}`}</Text>
            </div>
            <div className="TreatmentExerciseCardLevel">
              <Text className="TreatmentExerciseCardLevelText">{`${t('level')} ${treatmentLevel}`}</Text>
            </div>
          </div>
          <div className="TreatmentExerciseCardVideoBlueprint" >
            <img className="TreatmentThumbnail" alt="thumbnail" src={thumbnail} />
          </div>
        </div>
        <div className="TreatmentExerciseCardHover" onClick={selectVideo}>
          <div className="TreatmentExerciseCardHoverCircle">
            <Text className="TreatmentExerciseCardHoverCircleText">{t('seeExercise')}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};
