import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { en } from "./en";
import { es } from "./es";

const resources = {
  es,
  en,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("language") || "es",
    fallbackLng: "en",
    // disabled in production
    debug: false,
    // can have multiple namesapce, in case you want to devide a huge
    // translation into smaller pieces and load them on demand

    interpolation: {
      espaceValue: false,
      formatSeparator: ",",
    },
  });

export default i18n;
