import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element, redirectTo = "/login" }) => {
  const isAuthenticated = localStorage.getItem("token") !== null;

  if (!isAuthenticated) {
    return <Navigate to={redirectTo} />;
  }

  return element;
};

export default ProtectedRoute;
