import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUserData } from "../../redux/userInformation/UserInfo";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getData as getAllpainDefi,
  getStatus,
} from "../../redux/Paindefi/painDefiSlice";
import { fetchPainForm } from "../../redux/APIs";
import { ImageButtonCarousal, Text, ScreenContainer } from "../../components";
import "./PainDefi.scss";

const PainDefi = () => {
  const data = useSelector(getAllpainDefi);
  const status = useSelector(getStatus);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState();

  function onClick(id) {
    setSelectedButton(id);
    fetchPainForm(dispatch, id);
    dispatch(setUserData({ painDefinitionId: id }));
    setTimeout(() => {
      Navigate("/painLocalization");
    }, 500);
  }
  return (
    <ScreenContainer status={status}>
      <div className="ImageCarousalPageContainer">
        <Text variant={"Heading3 Center PaddingBottom"}>{t("bodyPara2")}</Text>
        <ImageButtonCarousal
          data={data}
          onClick={onClick}
          selectedButton={selectedButton}
        />
      </div>
    </ScreenContainer>
  );
};

export default PainDefi;
