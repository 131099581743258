import React from "react";
import './Text.scss';
import cx from "classnames";

export const Text = (props) => {
  const { variant, className, ...rest } = props;

  return (
    <div className={cx(variant, className)} {...rest}>{props.children}</div>
  );
};

Text.defaultProps = {
  variant: 'Satoshi'
};
