import React from "react";
import './ScreenContainer.scss';
import { Header, Footer, Loader } from "..";
import cx from "classnames";

export const ScreenContainer = (props) => {
  const { status, overlay, containerClassName, screenBackgroundClassName, headerRightArea, LoaderComponent } = props;
  return (
    <div className={cx("ScreenBackground", screenBackgroundClassName)}>
      <Header headerRightArea={headerRightArea}/>
      <div className={cx("Container", containerClassName)}>
        {status === 'success' ? props.children : <LoaderComponent />}
      </div>
      {overlay}
      <Footer />
    </div>
  );
};

ScreenContainer.defaultProps = {
  status: 'success',
  overlay: null,
  headerRightArea: null,
  LoaderComponent: Loader,
}
