import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  status: 'idle',
};

const painAreaSlice = createSlice({
  name: "painArea",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
      state.status = 'success';
    },
    setLoadingStatus: (state) => {
      state.status = 'loading';
    },
  },
});

export const { setLoadingStatus, setData } = painAreaSlice.actions;

export const getData = (state) => state.painArea.data;
export const getStatus = (state) => state.painArea.status;
export default painAreaSlice.reducer;
