import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {
    age: null,
    painAreaId: null,
    painDefinitionId: null,
    painBehaviorId: null,
    questionAnswers: [],
    diagnosisIds: [],
  },
};

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const payload = action.payload;
      state.userData = { ...state.userData, ...payload };
    },
  },
});

export const { setUserData } = userInfoSlice.actions;

export default userInfoSlice.reducer;
