import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ScreenContainer, Text, Button } from "../../components";
import './Dignosisdisclaimer.scss';

const DignosisDisclaimer = () => {
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const token = localStorage.getItem("token");

  const handleClick = () => {
    if (token) {
      Navigate("/diagnosis")
    } else {
      Navigate("/login")
    }
  }


  return (
    <ScreenContainer containerClassName={"DisclaimerBackground"}>
      <div className="DisclaimerContainer">
        <Text className="DisclaimerHeading" variant="Heading3">{t("importantInformation")}</Text>
        <Text className="DisclaimerText" variant="Paragraph2">{t("importantPara1_1")}<u><b>{t("importantPara1_2")}</b></u>{t("importantPara1_3")}</Text>
        <Text className="DisclaimerText" variant="Paragraph2">{t("importantPara2")}</Text>
        <Text className="DisclaimerText" variant="Paragraph2">{t("importantPara3")}</Text>
      </div>
      <Button className="DisclaimerTextButton" onClick={handleClick}>{t("seeAssessmentText")}</Button>
    </ScreenContainer>
  );
};

export default DignosisDisclaimer;
