import React from "react";
import './QuestionContent.scss';
import { Text, QuestionButton } from "..";
import { useTranslation } from "react-i18next";

export const QuestionContent = (props) => {
  const { data, currentIndex, shouldHighlight, onClick } = props;
  const { t } = useTranslation();
  return (
    <div className="QuestionContent">
      <img
        src={`${data[currentIndex]?.gifUrl}`}
        alt=""
        className="QuestionImage"
      />
      <div className="QuestionRightSection">
        <Text className={"QuestionText"} variant={"Paragraph2"}>{data[currentIndex].question}</Text>
        <div className="QuestionButtons">
          <QuestionButton
            selected={shouldHighlight(data[currentIndex]?._id, true)}
            onClick={() => onClick(data[currentIndex]._id, true)}
          >
            {t("yesButton")}
          </QuestionButton>
          <QuestionButton
            selected={shouldHighlight(data[currentIndex]?._id, false)}
            onClick={() => onClick(data[currentIndex]._id, false)}
          >
            {t("noButton")}
          </QuestionButton>
        </div>
      </div>
    </div>
  );
};
