import axios from "axios";
import { toast } from "react-toastify";

import { setData as addPainArea, setLoadingStatus as setLoadingStatusPainArea } from "../painBody/painAreaSlice";
import { setData as addPainDefi, setLoadingStatus as setLoadingStatusPainDefi } from "../Paindefi/painDefiSlice";
import { setData as addPainForm, setLoadingStatus as setLoadingStatusPainForm } from "../painForm/painFormSlice";
import { addDiagonsis, addPainBehaviorId, addPainQuestion } from "../questions/painQuestionsSlice";
import { getLanguage } from "./utils";

export const fetchPainArea = async (dispatch) => {
  dispatch(setLoadingStatusPainArea());
  const responce = await axios
    .get(`${process.env.REACT_APP_PROD_API}/painAreas/${getLanguage()}`)
    .catch((err) => {
      // console.log("err", err);
      toast.error("Error fetching pain Area");
    });
  dispatch(addPainArea(responce.data));
  // console.log(responce.data)
};

export const fetchPainDefi = async (dispatch, id) => {
  dispatch(setLoadingStatusPainDefi());
  const responce = await axios
    .get(
      `${process.env.REACT_APP_PROD_API
      }/painDefinitionsByPainAreaId/${getLanguage()}/${id}`
    )
    .catch((err) => {
      // console.log("err", err);
      toast.error("Error fetching pain Definations");
    });
  // console.log(responce?.data);
  dispatch(addPainDefi(responce.data));
};

export const fetchPainForm = async (dispatch, id) => {
  dispatch(setLoadingStatusPainForm());
  const responce = await axios
    .get(
      `${process.env.REACT_APP_PROD_API
      }/painBehaviorsByPainDefinition/${getLanguage()}/${id}`
    )
    .catch((err) => {
      // console.log("err", err);
      // toast.error("Error fetching pain Behavior");
    });
  //console.log(responce?.data);
  dispatch(addPainForm(responce.data));
};

export const fetchPainQuestions = async (dispatch, id) => {
  dispatch(addPainBehaviorId({ id }));
  const responce = await axios
    .get(
      `${process.env.REACT_APP_PROD_API
      }/questionsByPainBehavior/${getLanguage()}/${id}`
    )
    .catch((err) => {
      // console.log("err", err);
      toast.error("Error fetching pain Questions");
    });
  //console.log(responce?.data);
  dispatch(addPainQuestion({ data: responce.data }));
};

export const calculateDiagnotics = async (dispatch, payload) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_PROD_API}/calculateDiagnotics/${getLanguage()}`,
      payload
    );

    dispatch(addDiagonsis(res?.data));
  } catch (error) {
    // console.log('calculateDiagnotics error', error);
  }
};
