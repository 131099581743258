import React from "react";
import './LoadingStudio.scss';
import { Text, Loader } from "..";
import { useTranslation } from "react-i18next";

export const LoadingStudio = () => {
  const { t } = useTranslation();

  return (
    <div className={'LoadingStudio'}>
      <Text className="LoadingStudioText" variant="Paragraph2 Center">{t("loadingStudioPara1")}</Text>
      <Text className="LoadingStudioText" variant="Paragraph2 Center">{t("loadingStudioPara2")}</Text>
      <Loader className={"LoadingStudioLoader"}/>
    </div>
  );
};
