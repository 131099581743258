import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./HomeScreen.scss";
import { IoIosSettings } from "react-icons/io";

import { Text, Button, FooterTopBar, Footer } from "../../components";

const HomeScreen = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Check if token exists in localStorage
  const token = localStorage.getItem("token");

  // Handle language change
  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e);
    localStorage.setItem("language", e);
  };

  // Handle logout: clear token and navigate to login
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("formData");
    navigate("/");
  };

  return (
    <>
      <div className="HomeContainer">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: 10,
            width: "95vw",
            position: "absolute",
            top: 0,
            gap: "10px",
          }}
        >
          {/* Language switch */}
          <div className="flex">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: 6,
                cursor: "pointer",
              }}
              onClick={() => handleLanguageChange("en")}
            >
              <span style={{ fontSize: "regular" }}>ENG</span>
              <div className="flag-icon">&#x1F1FA;&#x1F1F8;</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => handleLanguageChange("es")}
            >
              <span style={{ fontSize: "regular" }}>ESP</span>
              <div className="flag-icon">&#x1F1EA;&#x1F1F8;</div>
            </div>
          </div>

          {/* User Profile Dropdown (visible if token is present) */}
          {token && (
            <div className="relative">
              <div className="flex items-center cursor-pointer justify-center"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <span className="mr-1">
                  {t("userProfile")}
                </span>

                <div className="user-avatar">
                  {/* <img src="https://via.placeholder.com/40" alt="User" /> */}
                  <IoIosSettings />

                </div>
              </div>

              {/* Dropdown Menu */}
              {isDropdownOpen && (
                <div className="absolute right-0 mt-0 bg-white rounded-md shadow-lg px-8 py-1 text-[14px] whitespace-nowrap">
                  <button className="dropdown-item" onClick={handleLogout}>
                    {t("logout")}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div >

      <div className="Home-Background">
        <div className="Middle-Blur" />
      </div>
      <div className="Home-Screen-Top-Overlay" alt="">
        <div className="HomeTitle">
          <Text variant="Heading1">SUNNDIO</Text>
          <Text className={"HomeTitleDescription"} variant="Heading4">
            {t("homeTitleDescription")}
          </Text>
        </div>
      </div>
      <div className="Home-Screen-Remaining-Overlay" alt="">
        <div className="HomeControls">
          <Text className={"HomeQuestion"} variant="Heading3">
            <p>{t("homeQuestion")}</p> <p>{t("homeQuestion1")}</p>
          </Text>
          <Button
            className={"HomeButton"}
            onClick={() => navigate("/patientForm")}
          >
            {t("homeButtonText")}
          </Button>

          {!token && (
            <>
              <p className="p-0 m-0">{t("or")}</p>
              <button className="Button mt-2">
                <Link to="/home/login" className="ButtonText">
                  {t("login").toUpperCase()}
                </Link>
              </button>
            </>
          )}
        </div>
      </div>
      <img className="Home-Circle-Top-Overlay" alt="" />
      <div className="Home-Screen-Bottom-Overlay">
        <FooterTopBar />
        <div className="Home10">
          <img className="Home10background" alt="" />
          <Text className={"Home10Text"}>10</Text>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomeScreen;
