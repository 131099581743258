import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScreenContainer, Text } from "../../components";
import './TreatmentLoading.scss';
import { useSelector } from "react-redux";
import { getAllTreatments, getTreatmentError, getTreatmentPercentageLoaded } from "../../redux/treatments/treatmentsSlice";
import { toast } from "react-toastify";

const TreatmentLoading = () => {
  const percentage = useSelector(getTreatmentPercentageLoaded);
  const data = useSelector(getAllTreatments);
  const error = useSelector(getTreatmentError);
  const Navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (error !== '') {
      toast.error(error);
    }
    if (data) {
      Navigate("/treatment");
    }
  }, [error, data, Navigate])

  return (
    <ScreenContainer>
      <div className={'TreatmentLoading-Screen-Content'}>
        <div>
          <Text className="TreatmentLoading-Description" variant="Heading4">{t('treatmentLoadingText')}</Text>
          <Text className="TreatmentLoading-Percentage" variant="Heading4 PaddingTop24">{percentage}%</Text>
          <img className="Treatment-Loader" alt="" />
        </div>
        <div />
      </div>
    </ScreenContainer>
  );
};

export default TreatmentLoading;
