import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { ScreenContainer, Text } from "../../components";
import "./Welcomescreen4.scss";

const WelcomeScreen4 = () => {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({});

  useEffect(() => {
    const data = localStorage.getItem("formData");
    if (data) {
      setFormData(JSON.parse(data));
      setTimeout(() => {
        Navigate("/body");
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const welcomeHeading = useMemo(() => {
    switch (i18n.language) {
      case "es":
        if (formData.gender === "male") {
          return t("welcomeHeading");
        } else {
          return t("welcomeHeadingFemale");
        }
      default:
        return t("welcomeHeading");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, formData.gender]);

  return (
    <ScreenContainer
      overlay={
        <>
          <img className="Welcome-Circle-Top-Overlay" alt="" />
          <img className="Welcome-Circle-Bottom-Overlay" alt="" />
        </>
      }
    >
      <div className={"Welcome-Screen-Content"}>
        <div>
          <Text variant="Heading3 Center">
            {welcomeHeading} &nbsp;{formData.name}
          </Text>
          <Text className="Welcome-Description" variant="Heading4 PaddingTop24">
            {t("para")}
          </Text>
        </div>
        <div />
      </div>
    </ScreenContainer>
  );
};

export default WelcomeScreen4;
