import React from "react";
import './Loader.scss';
import cx from "classnames";

export const Loader = (props) => {
  const { className } = props;

  return (
    <div className={cx('loading', className)}>
      <div className="loading__dot" />
      <div className="loading__dot" />
      <div className="loading__dot" />
    </div>
  );
};
