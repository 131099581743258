import React from "react";
import './ImageButtonCarousal.scss';
import { ImageButton } from "./ImageButton";
import cx from "classnames";

export const ImageButtonCarousal = (props) => {
  const { data, onClick, selectedButton } = props;
  const isSelected = (item) => item._id === selectedButton;
  return (
    <div className={"ImageCarousal"}>
      {data.map((item) => {
        return (
          <ImageButton
            key={item._id}
            buttonClass={cx({ "ImageButtonSelected": isSelected(item) })}
            buttonTextClass={cx({ "ImageButtonTextSelected": isSelected(item) })}
            imageUrl={item?.imageUrl}
            onClick={() => onClick(item._id)}
            label={item.name}
          />
        );
      })}
    </div>
  );
};
