import axios from 'axios'
const host = process.env.REACT_APP_PROD_API;

const api = axios.create({
    baseURL: host,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
    validateStatus: () => true,
})

api.interceptors.request.use( async function (config) {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
})
export { api }
