import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUserData } from "../../redux/userInformation/UserInfo";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fetchPainQuestions } from "../../redux/APIs";
import {
  getData as getAllpainForm,
  getStatus,
} from "../../redux/painForm/painFormSlice";
import { ImageButtonCarousal, Text, ScreenContainer } from "../../components";

const PainForm = () => {
  const data = useSelector(getAllpainForm);
  const status = useSelector(getStatus);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState();

  function onClick(id) {
    setSelectedButton(id);
    fetchPainQuestions(dispatch, id);
    dispatch(setUserData({ painBehaviorId: id }));
    setTimeout(() => {
      Navigate("/questions");
    }, 500);
  }

  return (
    <ScreenContainer status={status}>
      <div className="ImageCarousalPageContainer">
        <Text variant={"Heading3 Center PaddingBottom"}>{t("bodyPara3")}</Text>
        <ImageButtonCarousal
          data={data}
          onClick={onClick}
          selectedButton={selectedButton}
        />
      </div>
    </ScreenContainer>
  );
};

export default PainForm;
