import { configureStore } from "@reduxjs/toolkit";
import painAreaReducer from "./painBody/painAreaSlice";
import painDefiReducer from "./Paindefi/painDefiSlice";
import painFormReducer from "./painForm/painFormSlice";
import painQuestionsReducer from "./questions/painQuestionsSlice";
import painDiagnosisReducer from "./painDiagnosis/painDiagnosisSlice";
import treatmentsReducer from "./treatments/treatmentsSlice";
import UserInfo from "./userInformation/UserInfo";

export const store = configureStore({
  reducer: {
    painArea: painAreaReducer,
    painDefi: painDefiReducer,
    painForm: painFormReducer,
    painQuestions: painQuestionsReducer,
    painDiagnosis: painDiagnosisReducer,
    treatments: treatmentsReducer,
    userInfo: UserInfo,
  },
});
