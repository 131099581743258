import React, { useEffect, useState } from "react";
import './TreatmentIntro.scss';
import { Text } from "../Text";
import { useTranslation } from "react-i18next";

export const TreatmentIntro = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const data = localStorage.getItem("formData");
    if (data) {
      setFormData(JSON.parse(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="TreatmentIntro">
      <Text className="TreatmentIntroHeading">{`${t('congratulations')} ${formData.name}! `}</Text>
      <Text className="TreatmentIntroDescription" dangerouslySetInnerHTML={{ __html: t('treatmentInfo') }} />
    </div>
  );
};
