import React from "react";
import './VerticalNavBar.scss';
import cx from "classnames";
import { Text } from '..';

export const VerticalNavBar = (props) => {
  const { className, menuOpen, toggleMenu, config, ...rest } = props;

  return (
    <>
      <div className={cx({ "NavBarOverlay": menuOpen })} onClick={toggleMenu} />
      <div className={cx('NavBar', className, { "NavBarOpen": menuOpen })} {...rest}>
        <div className="NavBarHeader">
          <Text className="NavBarHeaderText">{config.title}</Text>
        </div>
        <div className="NavBarUnderline" />
        {
          config.buttons.map(button => (
            <div
              key={button.label}
              className={cx('NavBarButtonContainer', {
                "NavBarButtonContainerSelected": button.selected
              })}
              onClick={button.onClick}
            >
              <img className="NavBarButtonImage" alt="" src={button.svg} />
              <Text className="NavBarButtonText">{button.label}</Text>
            </div>
          ))
        }
      </div>
    </>
  );
};
