import React from "react";
import './RatingBar.scss';
import cx from "classnames";

export const RatingBar = (props) => {
  const { className, rating, ...rest } = props;

  return (
    <div className={cx('RatingBar', className)} {...rest}>
      {Array(5).fill().map((_, index) => (
        <div key={index} className={cx("RatingDot", {
          "RatingDotFilled": rating >= index + 1
        })} />
      ))}
    </div>
  );
};
