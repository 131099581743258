import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../redux/userInformation/UserInfo";
import { useTranslation } from "react-i18next";
import { getAllpainQuestions } from "../../redux/questions/painQuestionsSlice";
import { useNavigate } from "react-router-dom";
import { calculateDiagnotics } from "../../redux/APIs";
import {
  ProgressBar,
  QuestionContent,
  ScreenContainer,
  Text,
  ReturnButton,
  LoadingStudio,
} from "../../components";
import "./Question.scss";

const Questions = () => {
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const [currentIndex, setCurrentIndex] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [progress, setProgress] = useState(10);
  const [showLoadingStudio, setShowLoadingStudio] = useState(true);

  const questionData = useSelector(getAllpainQuestions);

  const data = questionData?.painQuestions;

  const [payload, setPayload] = useState({
    questionAnswer: [],
    painBehaviorId: questionData?.painBehaviourId,
  });

  useEffect(() => {
    setTimeout(() => {
      setShowLoadingStudio(false);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onClick(questionId, isYes) {
    setButtonDisabled(true);
    let questionAnswer = payload?.questionAnswer;
    const findIndex = questionAnswer.findIndex(
      (q) => q.questionId === questionId
    );
    if (findIndex >= 0) {
      questionAnswer[findIndex] = { questionId, isYes };
    } else {
      questionAnswer.push({ questionId, isYes });
    }
    setPayload((prev) => ({ ...prev, questionAnswer }));
    setTimeout(() => {
      setButtonDisabled(false);
      handleNext();
    }, 500);
  }

  const shouldHighlight = (id, isYes) => {
    const isIdPresentAndYes = payload.questionAnswer.some(
      (qa) => qa.questionId === id && qa.isYes === isYes
    );

    return isIdPresentAndYes;
  };

  const handleNext = async () => {
    if (data.length === currentIndex + 1) {
      calculateDiagnotics(dispatch, payload);
      Navigate("/importantInformation");
    } else {
      if (payload.questionAnswer[currentIndex]) {
        setCurrentIndex((prev) => prev + 1);
      }
    }
  };

  const handlePrevious = () => {
    if (currentIndex !== 0) {
      setCurrentIndex((prev) => prev - 1);
    }
    setProgress(progress - 10);
  };

  useEffect(() => {
    if (payload?.questionAnswer.length === 8) {
      dispatch(setUserData({ questionAnswers: payload?.questionAnswer }));
    }
  }, [payload, dispatch]);

  return (
    <ScreenContainer
      containerClassName="QuestionMainContainer"
      screenBackgroundClassName="QuestionMainScreenContainer"
    >
      {showLoadingStudio ? (
        <LoadingStudio />
      ) : (
        <div className="QuestionScreenContainer">
          <div className="Flex1" />
          <Text className={"QuestionHeadingText"} variant={"Heading3 Center"}>
            {t("questionsHeading")}
          </Text>
          <ProgressBar
            className={"ProgressBarMargin"}
            currentStep={currentIndex}
            steps={data}
          />
          {data && data.length > 0 && (
            <QuestionContent
              data={data}
              shouldHighlight={shouldHighlight}
              onClick={buttonDisabled ? () => {} : onClick}
              currentIndex={currentIndex}
            />
          )}
          {currentIndex !== 0 ? (
            <div className="QuestionReturnButtonContainer">
              <ReturnButton
                className={"QuestionReturnButton"}
                onClick={handlePrevious}
              />
            </div>
          ) : (
            <div className="Flex1" />
          )}
        </div>
      )}
    </ScreenContainer>
  );
};

export default Questions;
