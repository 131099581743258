import React from "react";
import './ImageButtonCarousal.scss';
import { Text } from '../Text';
import cx from "classnames";

export const ImageButton = (props) => {
  const { className, onClick, imageUrl, buttonClass, buttonTextClass, ...rest } = props;

  return (
    <button className={'ImageButton'} onClick={onClick} {...rest}>
      <img
        src={`${imageUrl}`}
        alt=""
        className="ImageButton-Image"
      />
      <div className={cx("ImageButton-Label", buttonClass)}>
        <Text className={cx("ImageButtonText", buttonTextClass)} variant={"Heading4"}>{props.label}</Text>
      </div>
    </button>
  );
};
