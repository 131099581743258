import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  status: 'idle',
};

const painFormSlice = createSlice({
  name: "painForm",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
      state.status = 'success';
    },
    setLoadingStatus: (state) => {
      state.status = 'loading';
    },
  },
});

export const { setLoadingStatus, setData } = painFormSlice.actions;

export const getData = (state) => state.painForm.data;
export const getStatus = (state) => state.painForm.status;
export default painFormSlice.reducer;
