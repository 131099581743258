import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import './TreatmentLevel.scss';
import { Text } from "../Text";
import { TreatmentExerciseCard } from "./TreatmentExerciseCard";
import { TreatmentVideo } from "./TreatmentVideo";

export const TreatmentLevel = ({ level, exercises }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const { t } = useTranslation();

  return (
    <>
      <div className="TreatmentLevel">
        <Text className="TreatmentLevelHeading">{t('objective')}</Text>
        <div className="TreatmentLevelCardsContainer">
          {exercises.map((exercise, index) => (
            <TreatmentExerciseCard key={index} {...exercise} count={index + 1} index={index} level={level} setSelectedVideo={setSelectedVideo} />
          ))}
        </div>
      </div>
      {selectedVideo !== null && <TreatmentVideo {...exercises[selectedVideo]} level={level} exercise={selectedVideo + 1} onReturn={() => setSelectedVideo(null)} />}
    </>
  );
};
