import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  painDiagnosis: [],
};

const painDiagnosisSlice = createSlice({
  name: "painDiagnosis",
  initialState,
  reducers: {
    addPainDiagnosis: (state, { payload }) => {
      state.painDiagnosis = payload;
    },
  },
});

export const { addPainDiagnosis } = painDiagnosisSlice.actions;

export const getAllPainDiagnosis = (state) => state.painDiagnosis.painDiagnosis;
export default painDiagnosisSlice.reducer;
