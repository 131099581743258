import React from "react";
import './HorizontalNavBar.scss';
import cx from "classnames";
import { Text } from '..';

export const HorizontalNavBar = (props) => {
  const { className, config, selected, selectButton, ...rest } = props;

  return (
    <div className={cx('HorizontalNavBar', className)} {...rest}>
      {
        config.buttons.map((button, index) => (
          <div
            key={index}
            className={cx('HorizontalNavBarButtonContainer', {
              "HorizontalNavBarButtonContainerSelected": selected === index,
              "HorizontalNavBarButtonContainerPrevious": selected > index,
            })}
            onClick={() => {
              selectButton(index);
              button.onClick();
            }}
          >
            <Text className="HorizontalNavBarButtonText">{button.label}</Text>
          </div>
        ))
      }
    </div>
  );
};
