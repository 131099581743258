import React from "react";
import './ToggleButtons.scss';
import { Text } from '../Text';
import cx from "classnames";

export const ToggleButtons = (props) => {
  const { className, buttons, error, onChange, name, value: selectedValue , text, ...rest } = props;

  return (
    <>
      <div className="ToggleButtonContainer" name={name}>
        <div style={{display:"flex",flexDirection:"column"}}>
      {text &&  <Text className={"TextFieldLabel"} style={{textAlign:"start"}} variant={"Heading4"}>{text}</Text>} 
        <div style={{display:"flex"}}>
        {buttons.map(({ label, value }) => (
          <button key={value} className={cx('ToggleButton', className, {
            "ToggleButtonSelected": selectedValue === value
          })} onClick={() => onChange(name, value)} {...rest}>
            <Text className={cx("ToggleButtonTextColor", {
              "ToggleButtonTextColorSelected": selectedValue === value
            })} variant={"Heading4"}>{label}</Text>
          </button>
        ))}
        </div>

        </div>
     
      </div>
      {error && <Text className="TextFieldErrorText">{error}</Text>}
    </>
  );
};
