import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { VerticalNavBar, ScreenContainer, HorizontalNavBar, TreatmentIntro, TreatmentLevel } from "../../components";
import './Treatment.scss';
import { TreatmentHeader } from "./TreatmentHeader";
import { useTranslation } from "react-i18next";
import DiagnosticIcon from "../../assets/images/diagnostics-icon.svg";
import TreatmentIcon from "../../assets/images/treatment-icon.svg";
import FollowUpIcon from "../../assets/images/followup-icon.svg";
import MenuIcon from "../../assets/images/menu-icon.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllTreatments } from "../../redux/treatments/treatmentsSlice";
const Treatment = (props) => {
  const data = useSelector(getAllTreatments);
  const { t } = useTranslation();

  const createHorizontalNavbarConfig = (data) => {
    const config = {
      Intro: {
        label: "Intro",
        onClick: () => Navigate(`/treatment`),
        path: '',
        element: <TreatmentIntro />,
      }
    };
    data.forEach((each) => {
      const key = 'level' + each.treatmentLevel;
      if (!config[key]) {
        config[key] = {
          label: t('level') + ' ' + each.treatmentLevel,
          onClick: () => Navigate(`/treatment/${key}`),
          path: key,
          element: <TreatmentLevel level={each.treatmentLevel} exercises={data.filter(_ => _.treatmentLevel === each.treatmentLevel)} />,
        }
      }
    });
    function sortByLabel(a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    }
    return Object.values(config).sort(sortByLabel);
  }
  const [menuOpen, setMenuOpen] = useState(false);
  const Navigate = useNavigate();
  const verticalNavbarConfig = {
    title: t("actions"),
    buttons: [
      {
        svg: DiagnosticIcon,
        label: t("diagnostics"),
        onClick: () => null,
      },
      {
        svg: TreatmentIcon,
        label: t("treatments"),
        selected: true,
        onClick: () => null,
      },
      {
        svg: FollowUpIcon,
        label: t("followup"),
        onClick: () => null,
      },
    ]
  }
  const [selectedLevel, setSelectedLevel] = useState(0)
  const horizontalNavbarConfig = {
    buttons: createHorizontalNavbarConfig(data)
  }

  const toggleMenu = () => setMenuOpen(state => !state);

  const menu = <div className="Menu-Button" onClick={toggleMenu}>
    <img className="Menu-Icon" alt="" src={MenuIcon} />
  </div>

  return (
    <ScreenContainer headerRightArea={menu}>
      <div className="Treatment-Container">
        <VerticalNavBar config={verticalNavbarConfig} toggleMenu={toggleMenu} menuOpen={menuOpen} />
        <div className="Treatment-Right-Section">
          <TreatmentHeader />
          <HorizontalNavBar config={horizontalNavbarConfig} selected={selectedLevel} selectButton={setSelectedLevel} />
          <div className="Treatment-Right-Section-NavBar-Border" />
          <div className={"Treatment-Content"}>
            <Routes>
              {horizontalNavbarConfig.buttons.map((button) => (
                <Route key={button.label} path={button.path} element={button.element} />
              ))}
            </Routes>
          </div>
        </div>
      </div>
    </ScreenContainer>
  );
};

export default Treatment;
