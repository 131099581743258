import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPainArea,
} from "../../redux/APIs";
import { ScreenContainer } from "../../components";
import { getStatus } from "../../redux/painBody/painAreaSlice";
import "./Body.scss";

import { Body } from "./Body";

const Index = () => {
  const dispatch = useDispatch();
  const status = useSelector(getStatus);

  useEffect(() => {
    fetchPainArea(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScreenContainer status={status}>
      <Body />
    </ScreenContainer>
  );
};

export default Index;
