import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { useTranslation } from "react-i18next";
import { getData as getAllpainArea } from "../../redux/painBody/painAreaSlice";
import { fetchPainDefi } from "../../redux/APIs";
import { setUserData } from "../../redux/userInformation/UserInfo";
import { Text } from "../../components";
import "./Body.scss";
import cx from "classnames";

export const Body = () => {
  const [formData, setFormData] = useState({});
  const data = useSelector(getAllpainArea);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState();

  const views = {
    FRONT: "front",
    BACK: "back",
  };

  const { FRONT, BACK } = views;

  useEffect(() => {
    const data = localStorage.getItem("formData");
    if (data) {
      setFormData(JSON.parse(data));
    }
  }, []);

  function onClick(id) {
    setSelectedButton(id);
    fetchPainDefi(dispatch, id);
    dispatch(setUserData({ painAreaId: id }));
    setTimeout(() => {
      Navigate("/painDefination");
    }, 500);
  }

  return (
    <div className="BodyContainer">
      <Text variant={"Heading3 Center"}>{t("bodyPara1")}</Text>
      <div className="flex justify-center items-center">
        {/* Front View */}
        <div className="ImageContent">
          <img
            alt="Front view"
            className={cx(
              "BodyImage",
              `BodyImage-${formData.gender}-${FRONT}`
            )}
          />
          {data
            .filter((item) => item.position === FRONT)
            .map((item) => {
              return (
                <button
                  key={item._id}
                  className={`ButtonOnImage ${selectedButton === item._id
                      ? "BodySelected BodyButtonText"
                      : ""
                    }`}
                  style={{
                    right: `${item.points[0]}%`,
                    bottom: `${item.points[1]}%`,
                  }}
                  onClick={() => onClick(item._id)}
                >
                  {item.name} {`>`}
                </button>
              );
            })}
        </div>

        {/* Back View */}
        <div className="ImageContent">
          <img
            alt="Back view"
            className={cx(
              "BodyImage",
              `BodyImage-${formData.gender}-${BACK}`
            )}
          />
          {data
            .filter((item) => item.position === BACK)
            .map((item) => {
              return (
                <button
                  key={item._id}
                  className={`ButtonOnImage ${selectedButton === item._id
                      ? "BodySelected BodyButtonText"
                      : ""
                    }`}
                  style={{
                    right: `${item.points[0]}%`,
                    bottom: `${item.points[1]}%`,
                  }}
                  onClick={() => onClick(item._id)}
                >
                  {item.name} {`>`}
                </button>
              );
            })}
        </div>
      </div>
    </div>
  );
};
