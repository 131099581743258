import React, { useState } from "react";
import { Field } from "formik";
import { LiaEyeSolid } from "react-icons/lia";
import { LiaEyeSlashSolid } from "react-icons/lia";

const InputField = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isPasswordInput = [
    "password",
    "newPassword",
    "reEnterNewPassword",
  ].includes(props.id);

  return (
    <div className="mb-2">
      <label htmlFor={props.id} className="block text-sm mb-1">
        {props.label}
      </label>
      <div className="relative">
        {isPasswordInput ? (
          <>
            <Field
              id={props.id}
              name={props.id}
              // value = {props.value}
              type={showPassword ? "text" : "password"}
              placeholder={props.placeholder || ""}
              className="input w-full border-[2px] border-r-cyan-200 py-2 px-4 rounded-[8px] focus:outline-none focus:border-cyan-400"
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <LiaEyeSlashSolid color="#555" size={20} />
              ) : (
                <LiaEyeSolid color="#555" size={20} />
              )}
            </div>
          </>
        ) : (
          <Field
            id={props.id}
            name={props.id}
            placeholder={
              props.placeholder
                ? props.placeholder
                : props.id === "otp"
                ? "Your 4 digit OTP"
                : ""
            }
            className="input w-full border-[2px] border-r-cyan-200 py-2 px-4 rounded-[8px] focus:outline-none focus:border-cyan-400"
          />
        )}
      </div>
      {props.children && (
        <div className="text-red-500 mt-2">{props.children}</div>
      )}
    </div>
  );
};

export default InputField;
