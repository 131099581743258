import React from "react";
import './Button.scss';
import { Text } from '../Text';
import cx from "classnames";

export const Button = (props) => {
  const { className, textClass, ...rest } = props;

  return (
    <button className={cx('Button', className)} {...rest}>
      <Text className={cx("ButtonText", textClass)}>{props.children}</Text>
    </button>
  );
};
