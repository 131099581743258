import React from "react";
import './FooterTopBar.scss';
import { useTranslation } from "react-i18next";
import { Text } from '../Text';

export const FooterTopBar = () => {
  const { t } = useTranslation();
  return (
    <footer className="FooterTopBar">
      <Text variant={'Heading4'}>{t("footerTopBarText")}</Text>
    </footer>
  );
};
