import React from "react";
import './TextField.scss';
import { Text } from '../Text';
import cx from "classnames";

export const TextField = (props) => {
  const { className, value, label, onChange, handleBlur, name, error } = props;

  return (
    <div className={cx("TextFieldContainer", className)}>
      <Text className={"TextFieldLabel"} variant={"Heading4"}>{label}</Text>
      <input
        className="TextFieldInput"
        type="text"
        value={value}
        name={name}
        onChange={onChange}
        onBlur={handleBlur}
      />
      {error && <Text className="TextFieldErrorText">{error}</Text>}
    </div>
  );
};
