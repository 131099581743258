import React from "react";
import './QuestionContent.scss';
import { Text } from "..";
import cx from "classnames";

export const QuestionButton = (props) => {
  const { onClick, selected, children } = props;
  return (
    <button
      className={cx("QuestionButton", {
        "QuestionButtonSelected": selected
      })}
      onClick={onClick}
    >
      <Text
        className={cx("QuestionButtonText", { "QuestionButtonTextSelected": selected })}
        variant={"Heading4"}>
        {children}
      </Text>
    </button>
  );
};
