import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  treatments: [],
  id: '',
  percentageLoaded: 0,
  error: '',
  diagnosisTitle: '',
};

const treatmentsSlice = createSlice({
  name: "treatments",
  initialState,
  reducers: {
    selectTreatment: (state, { payload }) => {
      state.id = payload?.id;
    },
    addTreatments: (state, { payload }) => {
      state.treatments = payload?.data;
      state.diagnosisTitle = payload?.diagnosisTitle;
    },
    setPercentageLoaded: (state, { payload }) => {
      state.percentageLoaded = payload?.percentage
    },
    setError: (state) => {
      state.error = "Error fetching treatments";
    }
  },
});

export const { selectTreatment, addTreatments, setPercentageLoaded, setError } = treatmentsSlice.actions;

export const getAllTreatments = (state) => state.treatments?.treatments;
export const getTreatmentError = (state) => state.treatments?.error;
export const getTreatmentPercentageLoaded = (state) => state.treatments?.percentageLoaded;
export const getDiagnosisTitle = (state) => state.treatments?.diagnosisTitle;

export default treatmentsSlice.reducer;
