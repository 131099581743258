import React, { useMemo } from "react";
import './DiagnosisProgressCard.scss';
import { Text } from "../Text";
import cx from "classnames";
import { useTranslation } from "react-i18next";

export const DiagnosisProgressCard = ({ element, index, selectedCard, onClick }) => {
  const isSelected = useMemo(() => selectedCard === index, [selectedCard, index]);
  const onCardClick = () => onClick(isSelected ? null : index);
  const { t } = useTranslation();
  const IndexBackgroundColor = [
    "FirstIndexColor", "SecondIndexColor", "ThirdIndexColor"
  ]
  return (
    <div
      key={element?.possibleDiagnostic}
      className={cx("ProgressCard", {
        "ProgressCardSelected": isSelected
      })}
      onClick={onCardClick}
    >
      <div className={cx("ProgressCardIndex", IndexBackgroundColor[index], {
        "ProgressCardIndexSelected": isSelected,
      })}><Text className="IndexText">{index + 1}</Text></div>
      <div className="ProgressBarContainer">
        <div className="GrayedProgressBar">
          <div style={{ width: `${element?.percentage}%` }} className="ColoredProgressBar" />
        </div>
        <Text className="ProgressBarText">{element?.percentage} %</Text>
      </div>
      <Text className="PossibleDiagnosticText">{element?.possibleDiagnostic}</Text>
      <Text className="MoreInfoText">{t("moreInfo")} &nbsp; &rarr;</Text>
    </div>
  )
};
