export const en = {
  translation: {
    home: "Home",
    Button: "Button",
    injuryHeading: "What injury do I have? What can I do to recover?",
    homeButton: "START DIAGNOSTICS",
    homeTitleDescription: "Boosting health and mobility with artificial intelligence",
    homeQuestion: "I feel pain/discomfort.",
    homeQuestion1: "What's happening to me?",
    homeButtonText: "START ASSESSMENT",
    // Screen NO.2 localization code
    mainHeading: "DIAGNOSTIC SUMMARY",
    subHeading: "The evaluation consists of 3 parts:",
    listPara1: "1. Knowledge of the patient",
    listPara2: "2. Pain behaviour",
    listPara3: "3. Symptom questions",
    introButton: "BEGIN",

    formHeading: "Patient",
    label1: "What is your name?",
    label2: "How old are you?",
    gender: "Gender",
    genderList1: "Male",
    genderList2: "Female",
    manual_evaluation: "Manual Evaluation",
    chatbot_evaluation: "AI CHAT BOT Evaluation",
    wanna_continue_with: "Wanna Continue with:",
    submitButton: "SUBMIT",
    previousButton: "PREVIOUS",

    welcomeHeading: "Welcome",
    para: "Let's start the test with our artificial intelligence assistant",
    nextButton: "NEXT",

    bodyPara1: "Where do you feel the pain?",
    bodyPara2: "How would you define pain?",
    bodyPara3: "Specifically:",

    studyHeading: "Study",

    loadingStudioPara1: "Next we need you to answer some questions about the symptoms",
    loadingStudioPara2: "We are preparing the evaluation test.",

    questionsHeading: "Evaluation Questions",
    yesButton: "YES",
    noButton: "NO",

    return: "Return",
    proceed: "Proceed",
    revolve: "Flip",

    importantInformation: "Important information:",
    importantPara1_1:
      " Sunndio diagnosis is a ",
    importantPara1_2:
      "tool under development",
    importantPara1_3:
      " by Sunndio health whose objective is to assist to patients and professionals in the diagnosis and treatment of mild muscoloesqeletal pathologies.",
    importantPara2:
      "Sunndio health recommends personally attending the physiotherapist in the most severe cases.",
    importantPara3:
      "In no case will the diagnosis and/or treatment offered by this tool prevail over the opinion of a physician.",
    importantPara5: "opinion of a physician.",
    seeAssessmentText: "SEE ASSESSMENT",
    footerText: "All rights reserved",
    footerTopBarText: "Years facilitating patient recovery",

    diagnosisResultsHeading: "The three most likely diagnoses:",
    moreInfo: "More Information",

    treatmentLoadingText: "We are preparing the treatment to overcome your physical barriers",
    descriptionHeading: "What is it?",
    treatmentHeading: "How is it treated?",
    recoveryTimeHeading: "Estimated recovery time:",
    durationLabel: "With treatment",
    treatmentButton: "Start Treatment",
    actions: "Actions",
    diagnostics: "Diagnostics",
    treatments: "Treatments",
    treatment: "Treatment",
    followup: "Follow-up",

    program: "Program",
    treatmentInfo: "Welcome to your new treatment with 100% personalized exercises. <br/><br/>We have prepared a plan that consists of several levels to be able to measure your degree of pain and <b>evolve with it until your complete recovery.</b><br/><br/>Go ahead! ! Select level 1 to start your treatment. <br/><br/>Once level 1 is completed, you can advance to the next level and so on.<br/><br/>Whenever you need additional support, you have our follow-up service at your disposal to ask questions, Ask for more information. To any problem we look for the most suitable solution for you!",
    congratulations: "Congratulations",
    level: "Level",
    exercise: "Exercise",
    difficulty: "Difficulty",
    duration: "Duration",
    seeExercise: "See exercise",
    objective: "Objective: Assessment and modulation of pain",

    nameValidator: "please enter your name",
    ageValidator: "please enter your age",
    genderValidator: "please enter your gender",

    // Login screen
    welcome: "Welcome back",
    loginWith: "Log In with your email address",
    emailAddress: "Email Address",
    password: "Password",
    forgotPassword: "Forgot Password",
    login: "Log In",
    notAccount: "You do not have an account?",
    signUpWithEmail: "Sign up with your email",

    // signup 

    surName: "Name and Surname",
    next: "Next",
    yourName: "What is your name?",
    age: "How old you are?",
    phoneNo: "Phone No",
    socialMedia: "Social Media URL (optional) (Facebook, Twitter, Instagram, etc.)",
    whichGender: "Please select your gender",
    alreadyAccount: "Do you already have a Sunndio account?",
    missOtp: "Have you miss the otp?",
    resend: "Resend",
    verify: "Verify",
    sendOtp: "Please enter the 4 digit code sent to your email",
    logout: "Logout",
    userProfile: "User Profile",

    // forgot password
    forgotPasswordQuestion: "Did you forget your password?",
    enterEmailSendOtp: "Please enter your email address for the verification process. We will send you a 4-digit code.",
    continue: "Continue",
    restorePassword: "Restore password",
    updatePasswordInfo: "Please set a new password for your account so you can log in and access all your data.",
    newPassword: "New Password",
    reEnterNewPassword: "Re-enter the password",
    or: "Or",
  },
};
