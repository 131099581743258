import React, { useState, createRef, useLayoutEffect } from "react";
import './DiagnosisDescriptionCard.scss';
import { Text, Button } from "..";
import cx from "classnames";
import { useTranslation } from "react-i18next";

export const DiagnosisDescriptionCard = ({ index, selectedCard, onClick }) => {
  const ref = createRef();
  const [showMore, setShowMore] = useState(false);
  const [showLink, setShowLink] = useState(false);

  useLayoutEffect(() => {
    if (ref.current.clientHeight < ref.current.scrollHeight) {
      setShowLink(true);
    }
  }, [ref]);

  const { t } = useTranslation();

  return (
    <div className="DescriptionCardMainContainer">
      <div
        className={cx("DescriptionCard", {
          "DescriptionCardExpanded": showMore
        })}
        onClick={e => e.stopPropagation()}
      >
        <div className="DescriptionCardHeader">
          <div className={"DescriptionCardIndex"}><Text className="DescriptionIndexText">{index + 1}</Text></div>
          <Text className="DescriptionTitleText">{selectedCard.possibleDiagnostic}</Text>
        </div>
        <div ref={ref}
          className={cx("DescriptionDetails", {
            "DescriptionDetailsExpanded": showMore
          })}>
          <Text className="DescriptionCardSectionHeading">{t("descriptionHeading")}</Text>
          <Text className="DescriptionCardSectorDescription" dangerouslySetInnerHTML={{ __html: selectedCard?.diagnosisDesc }} />
          <Text className="DescriptionCardSectionHeading">{t("treatmentHeading")}</Text>
          <Text className="DescriptionCardSectorDescription" dangerouslySetInnerHTML={{ __html: selectedCard?.treated }} />
        </div>
        {/* TODO: unhide the element below when client asks */}
        {/* <div className="DescriptionCardFooterContainer">
          <Text className="RecoveryTimeTitle">{t("recoveryTimeHeading")}</Text>
          <div className="DescriptionCardFooterRightContainer">
            <Text className="DescriptionCardFooterRightTopText">{t("durationLabel")}</Text>
            <Text className="DescriptionCardFooterRightBottomText">{selectedCard?.treatmentTime}</Text>
          </div>
        </div> */}
        {
          showLink &&
          <div className="DescriptionShowMore">
            <div className="DescriptionShowMoreButton" onClick={() => setShowMore(showMore => !showMore)}>
              <Text className={"ShowMoreText"}>See more:</Text>
              <img alt="" className={cx("ShowMoreImage", {
                "ShowMoreImageExpanded": showMore,
              })} />
            </div>
            <div className="HorizontalLine" />
          </div>
        }
        <Button className={"DescriptionCardButton"} textClass={"DescriptionCardButtonText"} onClick={onClick}>{t("treatmentButton")}</Button>
      </div>
    </div>
  )
};
