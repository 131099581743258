import React from 'react';
import { useTranslation } from 'react-i18next';
import OtpInputLib from 'react-otp-input';


const OtpInputComponent = ({ otp, setOtp }) => {
    const { t } = useTranslation();

    return (
        <div className='mt-8'>
            <p className="text-black mb-5">{t("sendOtp")}</p>
            <OtpInputLib
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span className='text-[#F1F0EC]'>-</span>}
                renderInput={(props) => <input id='otp' {...props} />}
                inputStyle={{
                    width: "100%",
                    height: "20px",
                    border: "none",
                    borderBottom: "1px solid black",
                    background: "none",
                    color: 'black',
                    outline: 'none',
                }}
                containerStyle="mt-2"
            />
        </div>

    );
};

export default OtpInputComponent;
