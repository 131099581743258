import React from "react";
import './ProgressBar.scss';
import cx from "classnames";

export const ProgressBar = (props) => {
  const { steps, currentStep, className } = props;

  return (
    <div className={cx('ProgressBarsContainer', className)}>
      {steps.map((_, i) => (
        <div
          key={_._id}
          className={cx("ProgressBar", {
            "ProgressBarStarting": i === 0,
            "ProgressBarEnding": i === steps.length - 1,
            "ProgressDone": i < currentStep + 1,
          })}
        />
      ))}
    </div>
  );
};

ProgressBar.defaultProps = {
  steps: [],
  currentStep: 1
}
