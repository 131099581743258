import { Formik, Form } from "formik";
import signupSrc from "../../../assets/images/forgotPassword.png";
import logo from "../../../assets/images/logo.svg";
import Button from "../../../components/AuthButton/Button";
import { useTranslation } from "react-i18next";
import { Footer } from "../../../components";
import { api } from "../../../api";
import { apiEndpoint } from "../../../api/apiEndpoints";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInputComponent from "./OtpInput";
import { useState } from "react";

const VerifyOtp = () => {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const { t } = useTranslation();
  const { state, pathname } = useLocation();
  const navigation = useNavigate();

  const initialValues = {
    otp: "",
  };

  const handleOtpVerification = async () => {
    setOtpError("");
    if (!otp) {
      setOtpError("This field is required");
    } else if (otp.length < 4) {
      setOtpError("Otp must have 4 digits");
    } else {
      const data = {
        otp: otp,
        userId: state,
        timeDifference: 300,
      };

      const response = await api.post(apiEndpoint.verifyOtp, data);

      if (response.data.responseStatus === 1) {
        if (pathname === "/verifyOtp") {
          navigation("/login");
        } else {
          navigation("/home/login", { state: { path: state?.path } });
        }
      } else {
        toast.error(response.data.error);
      }
    }
  };

  const resendOtp = async () => {
    const payload = {
      userId: state,
    };
    const response = await api.post(apiEndpoint.resendOtpCode, payload);

    if (response.data.responseStatus === 1) {
      toast.success("Otp send to the email");
    } else {
      toast.error(response.data.error);
    }
  };

  return (
    <>
      <div className="flex items-stretch h-screen ">
        <div className="w-screen h-full overflow-y-scroll sm:w-1/2  xl:p-16 p-8">
          <div className="flex items-center gap-2 xl:justify-start justify-center">
            <img src={logo} alt="" className="w-5 h-10 p-0 m-0" />
            <p className="xl:text-[20px] text-[14px] font-[700] p-0">
              SUNNDIO Health
            </p>
          </div>
          <div>
            <p className="xl:text-[42px] text-[20px] font-[700]  xl:mt-6 mt-4">
              {t("welcome")}
            </p>
          </div>
          <div>
            <p className="xl:text-[20px] text-[14px] font-[400] xl:mt-3 mt-2">
              {t("loginWith")}
            </p>
          </div>
          <Formik
            onSubmit={handleOtpVerification}
            initialValues={initialValues}
            // validationSchema={}
          >
            {({ errors, touched }) => (
              <Form className="flex flex-col xl:mt-2 mt-5 items-center sm:block h-42 sm:pl-16.5 w-full">
                <div className="w-full">
                  <div className="mb-10">
                    <OtpInputComponent otp={otp} setOtp={setOtp} />
                    {otpError && <p className="text-red-500">{otpError}</p>}
                  </div>

                  <Button text={t("verify")} />
                </div>
              </Form>
            )}
          </Formik>
          <p
            className="text-[16px] text-center font-[400] cursor-pointer"
            onClick={resendOtp}
          >
            {t("missOtp")} <span className="text-[#3b00d1]">{t("resend")}</span>
          </p>
        </div>
        <div className="items-center h-full w-1/2 hidden sm:flex bg-web-container justify-end">
          <img
            className="w-full h-full"
            src={signupSrc}
            alt="hand holding globe"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default VerifyOtp;
