import InputField from "../../components/InputField/InputField";
import { Formik, Form, Field } from "formik";
import { signupInfoSchema } from "../../utils/validationSchema";
import signupSrc from "../../assets/images/signup 2.png";
import logo from "../../assets/images/logo.svg";
import Button from "../../components/AuthButton/Button";
import { useTranslation } from "react-i18next";
import { Footer } from "../../components";
import { api } from "../../api";
import { apiEndpoint } from "../../api/apiEndpoints";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const SignupInfo = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigation = useNavigate();
  const [formData, setFormData] = useState({});
  const [loginPath, setLoginPath] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    const data = localStorage.getItem("formData");
    if (data) {
      setFormData(JSON.parse(data));
    }
  }, [state]);

  useEffect(() => {
    if (pathname === "/signup/info") {
      setLoginPath("/login");
    } else {
      setLoginPath("/home/login");
    }
  }, [pathname]);

  const initialValues = {
    name: formData.name ? formData.name : "",
    age: formData.age ? formData.age : "",
    gender: formData.gender ? formData.gender : "",
    phoneNo: formData.phoneNo ? formData.phoneNo : "",
    socialMedia: formData.socialMedia ? formData.socialMedia : "",
  };

  const handleSignup = async (values) => {
    const data = {
      email: state.email.toLowerCase(),
      password: state.password,
      age: values?.age,
      name: values?.name,
      gender: values?.gender,
      phoneNo: values?.phoneNo,
      socialMedia: values?.socialMedia,
    };

    localStorage.setItem("formData", JSON.stringify(values));

    const response = await api.post(apiEndpoint.signUp, data);
    console.log({ response });

    if (response.data.responseStatus === 1) {
      if (pathname === "/signup/info") {
        navigation("/verifyOtp", { state: response?.data?.userId });
      } else {
        navigation("/home/verifyOtp", {
          state: response?.data?.userId,
          path: state?.path,
        });
      }
    } else {
      toast.error(response.data.error);
    }
  };

  return (
    <>
      <div className="flex h-screen items-stretch text-black">
        <div className="w-screen h-full overflow-y-scroll sm:w-1/2  xl:px-16 p-8 xl:pb-[10%]">
          <Link
            to="/"
            className="flex items-center gap-2 xl:justify-start justify-center"
          >
            <img src={logo} alt="" className="w-5 h-10 p-0 m-0" />
            <p className="xl:text-[20px] text-[14px] font-[700] p-0">
              SUNNDIO Health
            </p>
          </Link>
          <div>
            <p className="xl:text-[42px] text-[20px] font-[700]  xl:mt-6 mt-4">
              {t("welcome")}
            </p>
          </div>
          <div>
            <p className="xl:text-[20px] text-[14px] font-[400] xl:mt-3 mt-2">
              {t("loginWith")}
            </p>
          </div>
          <Formik
            onSubmit={handleSignup}
            validationSchema={signupInfoSchema}
            initialValues={initialValues}
            enableReinitialize
          >
            {({ errors, touched, values }) => (
              <Form className="flex flex-col justify-center xl:mt-2 mt-5 items-center sm:block h-42 sm:pl-16.5 w-full">
                <div className="w-full">
                  <InputField id="name" label={t("yourName")}>
                    {errors.name && touched.name ? (
                      <div className="text-red-500">{errors.name}</div>
                    ) : null}
                  </InputField>
                  <InputField id="age" label={t("age")}>
                    {errors.age && touched.age ? (
                      <div className="text-red-500">{errors.age}</div>
                    ) : null}
                  </InputField>
                  <InputField id="phoneNo" label={t("phoneNo")}>
                    {errors.phoneNo && touched.phoneNo ? (
                      <div className="text-red-500">{errors.phoneNo}</div>
                    ) : null}
                  </InputField>
                  <InputField
                    id="socialMedia"
                    label={t("socialMedia")}
                    placeholder="https://facebook.com"
                  >
                    {errors.socialMedia && touched.socialMedia ? (
                      <div className="text-red-500">{errors.socialMedia}</div>
                    ) : null}
                  </InputField>
                  <div className="mb-5 w-full">
                    <label className="block text-sm text-gray-600 mb-2">
                      {t("whichGender")}
                    </label>
                    <div className="flex items-center justify-center space-x-4">
                      <label className="flex items-center">
                        <Field
                          id="gender"
                          type="radio"
                          name="gender"
                          value="male"
                          className="hidden"
                        />
                        <span
                          className={`xl:w-[300px] sm:w-[200px] w-[150px] ${
                            values?.gender === "male" ? "bg-gray-100" : ""
                          } h-14 flex items-center justify-center border border-gray-300 rounded-md cursor-pointer transition duration-300 hover:bg-gray-100`}
                        >
                          {t("genderList1")}
                        </span>
                      </label>
                      <label className="flex items-center">
                        <Field
                          id="gender"
                          type="radio"
                          name="gender"
                          value="female"
                          className="hidden"
                        />
                        <span
                          className={`xl:w-[300px] sm:w-[200px] w-[150px] ${
                            values?.gender === "female" ? "bg-gray-100" : ""
                          } h-14 flex items-center justify-center border border-gray-300 rounded-md cursor-pointer transition duration-300 hover:bg-gray-100`}
                        >
                          {t("genderList2")}
                        </span>
                      </label>
                    </div>
                    {errors.gender && touched.gender ? (
                      <div className="text-red-500">{errors.gender}</div>
                    ) : null}
                  </div>
                  <Button text={t("next")} />
                </div>
              </Form>
            )}
          </Formik>
          <p className="text-[16px] text-center font-[400]">
            {t("alreadyAccount")}{" "}
            <Link to={loginPath} className="text-[#3b00d1]">
              {t("login")}
            </Link>
          </p>
        </div>
        <div className="items-center w-1/2 hidden sm:flex bg-web-container justify-end">
          <img
            className="w-full h-full"
            src={signupSrc}
            alt="hand holding globe"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SignupInfo;
