import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ScreenContainer, Text, Button } from "../../components";
import "./DignosisIntroScreen2.scss";

const DignosisIntroScreen2 = () => {
  const Navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <ScreenContainer>
      <Text variant={"Heading3"}>{t("subHeading")}</Text>
      <div className={"List"}>
        <Text variant={"Heading4 ListItem"}>{t("listPara1")}</Text>
        <Text variant={"Heading4 ListItem"}>{t("listPara2")}</Text>
        <Text variant={"Heading4 ListItem"}>{t("listPara3")}</Text>
      </div>
      <Button onClick={() => Navigate("/welcome")}>{t("introButton")}</Button>
    </ScreenContainer>
  );
};

export default DignosisIntroScreen2;
