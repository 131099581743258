import React, { useRef, useState, useEffect } from "react";
import cx from 'classnames';
import './TreatmentVideo.scss';
import { Loader, ReturnButton/* , Text */ } from '..'

const durationToSeconds = (duration) => {
  const [minutes, seconds] = duration.split(':');
  return (parseInt(minutes) * 60) + parseInt(seconds);
}

export const TreatmentVideo = ({ duration, onReturn, treatmentUrl, thumbnail }) => {
  const videoRef = useRef(null);
  const [videoTime, setVideoTime] = useState(durationToSeconds(duration));
  const [loading, setLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [startVideo, setStartVideo] = useState(false);

  const videoHandler = (control) => {
    if (control === "play") {
      videoRef.current.pause();
      videoRef.current.play();
      setTimeout(() => {
        setVideoTime(parseInt(videoRef.current.duration));
        setStartVideo(true);
      }, 250);
    } else if (control === "pause") {
      videoRef.current.pause();
      setStartVideo(false);
    } else if (control === "play-from-start") {
      setCurrentTime(0);
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };
  const onloadedmetadata = () => {
    setLoading(false);
    videoHandler("play");
  }
  useEffect(() => {
    let intervalId = null;
    if (startVideo) {
      if (intervalId) clearInterval(intervalId);
      intervalId = setInterval(() => {
        setCurrentTime(videoRef.current.currentTime);
      }, 250);
    }
    return () => clearInterval(intervalId);
  }, [startVideo, videoRef])
  const barWidth = `calc(${(currentTime / videoTime) * 100}% - 11px)`;

  const videoControls = () => {
    return (
      <div className={cx("TreatmentButtons", {
        "TreatmentButtonLoading": loading
      })}>
        <div className="TreatmentButtonContainer" onClick={() => videoHandler("pause")}>
          <img className="PauseButton" alt="" />
        </div>
        <div className="TreatmentButtonContainer" onClick={() => videoHandler("play")}>
          <img className="PlayButton" alt="" />
        </div>
        <div className="TreatmentButtonContainer" onClick={() => videoHandler("play-from-start")}>
          <img className="PlayFromStartButton" alt="" />
        </div>
      </div>
    )
  }
  return (
    <div className="TreatmentVideoPlayer">
      <div className="TreatmentVideoInfo" />
      <div className="TreatmentVideoContainer">
        <video
          id="treatment-video"
          ref={videoRef}
          className="TreatmentVideo"
          src={treatmentUrl}
          poster={thumbnail}
          preload="auto"
          onLoadedMetadata={onloadedmetadata}
        ></video>
      </div>
      {loading && <Loader className="TreatmentVideoLoader" />}
      <div className="TreatmentVideoDurationBarContainer">
        <div className="TreatmentVideoDurationBar">
          <div className="TreatmentVideoDurationBarProgress" style={{ width: barWidth }} />
        </div>
      </div>
      <div className="MobileVideoControls">
        {videoControls()}
      </div>
      <div className="TreatmentVideoControls">
        <div className="DesktopVideoControls">
          {videoControls()}
        </div>
        <ReturnButton className="TreatmentVideoReturnButton" onClick={onReturn} />
      </div>
    </div>
  );
};
