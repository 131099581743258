import React from "react";
import './ReturnButton.scss';
import { Text } from '../Text';
import cx from "classnames";
import { useTranslation } from "react-i18next";

export const ReturnButton = (props) => {
  const { className, ...rest } = props;
  const { t } = useTranslation();

  return (
    <button className={cx('ReturnButton', className)} {...rest}>
      <img className="ReturnButtonImage" alt=""/>
      <Text className={"ReturnButtonTextColor"} variant={"Heading4"}>{t("return")}</Text>
    </button>
  );
};
