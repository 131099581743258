import React from "react";
import './Treatment.scss';
import { Text } from "../../components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDiagnosisTitle } from "../../redux/treatments/treatmentsSlice";

export const TreatmentHeader = () => {
  const { t } = useTranslation();
  const diagnosisTitle = useSelector(getDiagnosisTitle);
  return (
    <div className="Treatment-Header">
      <Text className="Treatment-Header-Breadcrumb">{`${t("program")} - ${t("treatment")}`}</Text>
      <Text className="Treatment-Header-Title">{diagnosisTitle}</Text>
    </div>
  );
};
