import InputField from "../../../components/InputField/InputField";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { updatePasswordSchema } from "../../../utils/validationSchema";
import logo from "../../../assets/images/logo.svg";
import bannerSrc from "../../../assets/images/forgotPassword.png";
import { useTranslation } from "react-i18next";
import Button from "../../../components/AuthButton/Button";
import { api } from "../../../api";
import { apiEndpoint } from "../../../api/apiEndpoints";
import { Footer } from "../../../components";
import OtpInputComponent from "../otpVerify/OtpInput";
import { toast } from "react-toastify";
import { useState } from "react";

const UpdatePassword = () => {
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const { pathname } = useLocation();

    const { t } = useTranslation()
    const navigation = useNavigate()
    const { state } = useLocation();

    const initialValues = {
        newPassword: "",
        reEnterNewPassword: "",
    }


    const handleUpdatePassword = async (values) => {
        setOtpError("");
        if (!otp) {
            setOtpError("This field is required")
        } else if (otp.length < 4) {
            setOtpError("Otp must have 4 digits")
        } else {
            const data = {
                userId: state,
                newPassword: values.newPassword,
                otpNumber: otp,
            };

            const response = await api.post(apiEndpoint.updateForgotPassword, data);

            if (response.data.responseStatus === 1) {
                if (pathname === "/updatePassword") {
                    navigation("/login")
                } else {
                    navigation("/home/login")
                }
            } else {
                toast.error(response.data.error)
            }
        }
    };

    return (
        <>
            <div className="flex h-screen text-black">
                <div className="w-screen h-full overflow-y-scroll sm:w-1/2 xl:p-16 p-8">
                    <div className="flex items-center gap-2 xl:justify-start justify-center">
                        <img src={logo} alt="" className="w-5 h-10 p-0 m-0" />
                        <p className="xl:text-[20px] text-[14px] font-[700] p-0" >
                            SUNNDIO Health
                        </p>
                    </div>
                    <div>
                        <p className="xl:text-[42px] text-[20px] font-[700]  xl:mt-6 mt-4">{t("restorePassword")}</p>
                    </div>
                    <div>
                        <p className="xl:text-[20px] text-[14px] font-[400] xl:mt-6 mt-4">{t("updatePasswordInfo")}</p>
                    </div>
                    <Formik
                        onSubmit={handleUpdatePassword}
                        initialValues={initialValues}
                        validationSchema={updatePasswordSchema}
                    >
                        {({ errors, touched }) => (
                            <Form className="flex flex-col items-center sm:block h-42 sm:pl-16.5 w-full">
                                <div className="w-full xl:mt-6 mt-6">
                                    <InputField id="newPassword" label={t("newPassword")}>
                                        {errors.newPassword && touched.newPassword ? (
                                            <div className="text-red-500">{errors.newPassword}</div>
                                        ) : null}
                                    </InputField>

                                    <InputField id="reEnterNewPassword" label={t("reEnterNewPassword")}>
                                        {errors.reEnterNewPassword && touched.reEnterNewPassword ? (
                                            <div className="text-red-500">{errors.reEnterNewPassword}</div>
                                        ) : null}
                                    </InputField>
                                    <div className="sm:mb-5 xl:mb-10 mb-10">
                                        <OtpInputComponent otp={otp} setOtp={setOtp} />
                                        {otpError && <p className="text-red-500">{otpError}</p>}
                                    </div>
                                    <Button text={t("continue")} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="justify-center items-center w-1/2 hidden sm:flex">
                    <img
                        className="w-[100%] h-full"
                        src={bannerSrc}
                        alt="Login"
                    />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default UpdatePassword;
