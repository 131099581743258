import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  painQuestions: [],
  painBehaviourId: null,
  diagonsis: [],
};

const painQuestionsSlice = createSlice({
  name: "painQuestions",
  initialState,
  reducers: {
    addPainBehaviorId: (state, { payload }) => {
      state.painBehaviourId = payload.id;
    },
    addPainQuestion: (state, { payload }) => {
      state.painQuestions = payload?.data;
    },
    addDiagonsis: (state, { payload }) => {
      state.diagonsis = payload;
    },
  },
});

export const { addPainBehaviorId, addPainQuestion, addDiagonsis } =
  painQuestionsSlice.actions;

export const getAllpainQuestions = (state) => state.painQuestions;
export const getDiagonsis = (state) => state.painQuestions?.diagonsis;
// export const getAllpainBehaviourId = (state) => state.painQuestions.painBehaviourId;
export default painQuestionsSlice.reducer;
